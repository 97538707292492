import { LOCALES } from '../constants';

const english = {
  [LOCALES.ENGLISH]: {
    'edit-file': 'Edit {file} and save to reload.',
    'hello': 'Hi! Im',
    'language': 'EN',
    'im': 'Im a ',
    'listim': 'Dog Lover',
    'Intro': 'Intro',
    'Portfolio': 'Portfolio',
    'Skills': 'Skills',
    'contact': 'Contact',
    'Contact': 'Contact',
    'Featured': 'Featured',
    'Web App': 'Web App',
    'Mobile App': 'Mobile App',
    'Design': 'Design',
    'Data':'Data',
    'bungo': 'BungoMyDingo is a personal proyect where i store all of my ilustrations, varying from the first thing that pops into my mind to all of my dogs!',
    'click':'Click here to view more!',
    'kimberly': 'I did my internship on Kimerly Klark, creating data structures for analysis and creating graphic interfaces that allowed machine optimization in order to have better performance.',
    'next':'Coming Soon',
    'developing' : 'Im currently working on many other projects! :D',
    'discord msg': 'Message me on Discord!',
    'instagram msg': 'Follow me on Instagram!',
    'linkedin msg': 'Find me on Linkedin!',
    'hv msg': 'Download my resume!',
    'contact msg': 'Contact',
    'msg': 'message',
    'send msg': 'send',
    'thanks msg': 'Thanks! Ill reply ASAP!',
  },
};
export default english;